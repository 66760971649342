@keyframes SKrkAG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SKrkAG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SKrkAG_multilineInput > :not(:first-child) {
  margin-top: 8px;
}

.SKrkAG_multilineInput .SKrkAG_firstFieldWithMultiInputs {
  padding-right: 36px;
}

.SKrkAG_multilineInput .SKrkAG_deletableInput {
  align-items: center;
  display: flex;
}

.SKrkAG_multilineInput .SKrkAG_deletableInput > :first-child {
  flex: 1;
}

.SKrkAG_multilineInput .SKrkAG_deletableInput > :not(:first-child) {
  margin-left: 8px;
}

.SKrkAG_multilineInput .SKrkAG_minusIcon {
  color: var(--color-text-secondary);
}

.SKrkAG_multilineInput .SKrkAG_addAnother {
  margin-top: 4px;
}

.SKrkAG_multilineInput .SKrkAG_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes _5hpe_G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _5hpe_G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._5hpe_G_headlineWithMultiInputs {
  padding-right: 36px;
}

@keyframes _6CCQEa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6CCQEa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6CCQEa_wrapper {
  align-items: flex-start;
  display: flex;
  position: relative;
}

._6CCQEa_wrapper ._6CCQEa_field, ._6CCQEa_wrapper ._6CCQEa_field ._6CCQEa_multiTextInput {
  flex: 1;
}

._6CCQEa_wrapper ._6CCQEa_saveButton {
  flex-shrink: 0;
  margin: 24px 0 0 8px;
}

@keyframes VqKMoq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes VqKMoq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.VqKMoq_card {
  flex-direction: column;
  padding: 20px 24px;
  scroll-margin: 20px;
  display: flex;
}

.VqKMoq_card .VqKMoq_header {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.VqKMoq_card .VqKMoq_header > svg {
  color: var(--color-text-secondary);
}

.VqKMoq_card .VqKMoq_header .VqKMoq_index {
  margin-right: 16px;
}

.VqKMoq_card .VqKMoq_buttonWrapper {
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
}

.VqKMoq_card .VqKMoq_content {
  height: 0;
  margin-top: 0;
  overflow: hidden;
}

.VqKMoq_card .VqKMoq_content.VqKMoq_expanded {
  height: auto;
  overflow: unset;
}

.VqKMoq_card .VqKMoq_content > :first-child {
  margin-top: 24px;
}

.VqKMoq_card li {
  font: var(--font-body-2);
}

.VqKMoq_card li ul, .VqKMoq_card li ol {
  padding-inline-start: 1ch;
}

.VqKMoq_card ul {
  padding-inline-start: 4ch;
}

.VqKMoq_card ul > li {
  margin-block: 8px;
  padding-inline-start: 4px;
}

.VqKMoq_card ol {
  padding-inline-start: 2ch;
}

.VqKMoq_card ol > li {
  margin-block: 12px;
  padding-inline-start: 4px;
}

.VqKMoq_card h3 {
  font: var(--font-title-2);
  color: var(--color-text-secondary);
  margin: 24px 0 12px;
}

.VqKMoq_card p {
  font: var(--font-body-2);
  margin: 12px 0;
}

.VqKMoq_card strong {
  font: var(--font-label-2);
}

.VqKMoq_card pre {
  margin: 12px 0;
}

.VqKMoq_card code:not(pre > code) {
  background: var(--color-layer-2);
  font: var(--font-body-2);
  border-radius: 4px;
  padding: 4px;
}

.VqKMoq_card + .VqKMoq_card {
  margin-top: 24px;
}

@keyframes RdP3iG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RdP3iG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RdP3iG_container {
  width: 100%;
  margin-top: 12px;
}

.RdP3iG_container ul {
  border-bottom: 1px solid var(--color-divider);
  margin: 4px 0;
  padding: 0;
  display: flex;
}

.RdP3iG_container ul li {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
  margin-right: 24px;
  padding-bottom: 4px;
  cursor: pointer;
  margin-block-end: unset;
  padding-inline-start: unset;
  list-style: none;
}

.RdP3iG_container ul li[aria-selected="true"] {
  color: var(--color-text-link);
  border-bottom: 2px solid var(--color-text-link);
  outline: none;
  margin-bottom: -1px;
}

.RdP3iG_container .RdP3iG_hidden {
  display: none;
}

@keyframes EKwVqa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes EKwVqa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.EKwVqa_alert {
  font: var(--font-body-2);
  border-radius: 8px;
  align-items: center;
  margin: 8px 0;
  padding: 12px 16px;
  display: flex;
}

.EKwVqa_alert.EKwVqa_shadow {
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-1);
}

.EKwVqa_alert .EKwVqa_icon {
  width: 20px;
  height: 20px;
  color: var(--color-text-secondary);
}

.EKwVqa_alert .EKwVqa_content {
  flex: 1;
  margin: 0 12px;
}

.EKwVqa_alert.EKwVqa_info {
  background: var(--color-surface-variant);
}

.EKwVqa_alert.EKwVqa_info .EKwVqa_icon {
  color: var(--color-neutral-variant-60);
}

/*# sourceMappingURL=ios.c10c7ff3.css.map */
